import React, { useContext, useEffect, useState } from "react";
import { Layout } from "../../components";
import {
  Button,
  Container,
  Row,
  ListGroup,
  Modal,
  Form,
} from "react-bootstrap";
import "./index.scss";
import { AuthContext } from "../../hooks/auth-context";
import {
  Announcements,
  Landlord,
  PropertyInformation,
  ServiceRequest,
  Tenant,
} from "../../utils/interfaces";
import {
  addServiceRequest,
  getAnnouncementsByPropertyToken,
  getLandlordInformation,
  getPropertyByToken,
  getTenantByUserID,
} from "../../supabase/database-functions";
import { token_generator } from "../../utils/token_generator";
import DataTable, { TableColumn } from "react-data-table-component";
import emailjs from "@emailjs/browser";

const TenantDashboardPage: React.FC = () => {
  const user = useContext(AuthContext);

  const [tenant, setTenant] = useState<Tenant>({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    move_in_date: "",
    move_out_date: "",
    rent: 0,
    rent_most_recent_payment_amount: 0,
    rent_most_recent_payment_date: "",
    user_uid: "",
    property_token: "",
  });

  const [property, setProperty] = useState<PropertyInformation>({
    address: "",
    tenants: [],
    landlord_uid: "",
    total_rent: 0,
    property_token: "",
  });
  const [landlord, setLandlord] = useState<Landlord>({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    user_uid: "",
    number_properties: 0,
  });
  const [announcements, setAnnouncements] = useState<Announcements[]>([]);

  const [showServiceRequestModal, setShowServiceRequestModal] =
    useState<boolean>(false);

  // Service Request Modal variables
  const [serviceRequest, setServiceRequest] = useState<ServiceRequest>({
    type_of_issue: "",
    severity_of_issue: "",
    date_issue_occured: "",
    message: "",
    property_token: property.property_token,
    service_request_token: "",
    completed: false,
  });

  const [viewAnnouncementsTable, setViewAnnouncementsTable] =
    useState<boolean>(true);
  const [viewPaymentsTable, setViewPaymentsTable] = useState<boolean>(false);

  // Columns for the announcements table
  const columnsAnnouncements: TableColumn<Announcements>[] = [
    {
      name: "Message",
      selector: (row) => row.message,
    },
    {
      name: "Date Sent",
      selector: (row) => row.date_sent,
    },
  ];

  const handleTableSelections = (
    announcements_table: boolean,
    payments_table: boolean
  ) => {
    if (announcements_table) {
      setViewAnnouncementsTable(true);
      setViewPaymentsTable(false);
    } else if (payments_table) {
      setViewAnnouncementsTable(false);
      setViewPaymentsTable(true);
    }
  };

  const handleCloseServiceRequestModal = () =>
    setShowServiceRequestModal(false);
  const handleShowServiceRequestModal = () => setShowServiceRequestModal(true);

  const handleClearFieldsServiceRequestModal = () => {
    setServiceRequest({
      type_of_issue: "",
      severity_of_issue: "",
      date_issue_occured: "",
      message: "",
      property_token: property.property_token,
      service_request_token: token_generator("service-request"),
      completed: false,
    });
  };

  const fetchTenantData = async () => {
    if (user?.user?.id) {
      try {
        const tenantInfo = await getTenantByUserID(user.user.id);
        if (tenantInfo) {
          setTenant(tenantInfo);
        }
      } catch (error) {
        alert("Error fetching tenant data: " + error);
      }
    }
  };

  const fetchPropertyData = async () => {
    if (tenant.property_token) {
      try {
        const propertyInfo = await getPropertyByToken(tenant.property_token);
        if (propertyInfo) {
          setProperty(propertyInfo);
        }
      } catch (error) {
        alert("Error fetching property data: " + error);
      }
    }
  };

  const fetchAnnouncementsData = async () => {
    const res = await getAnnouncementsByPropertyToken(property.property_token);
    if (res) {
      setAnnouncements(res.sort((a, b) => b.id! - a.id!));
    }
  };

  const fetchLandlordData = async () => {
    const res = await getLandlordInformation(property.landlord_uid);
    if (res) {
      setLandlord(res);
    }
  };

  useEffect(() => {
    fetchTenantData();
  }, [user]);

  useEffect(() => {
    fetchPropertyData();
  }, [tenant]);

  useEffect(() => {
    setServiceRequest({
      ...serviceRequest,
      property_token: property.property_token,
      service_request_token: token_generator("service-request"),
    });
    fetchAnnouncementsData();
    fetchLandlordData();
  }, [property]);

  const handleSubmitFormServiceRequest = async () => {
    if (
      serviceRequest.date_issue_occured &&
      serviceRequest.message &&
      serviceRequest.property_token &&
      serviceRequest.severity_of_issue &&
      serviceRequest.type_of_issue &&
      serviceRequest.service_request_token
    ) {
      const res = await addServiceRequest(serviceRequest);
      if (res) {
        emailjs.send(
          process.env.REACT_APP_SERVICE_ID_EMAILJS!,
          process.env.REACT_APP_BASIC_TEMPLATE_ID_EMAILJS!,
          {
            subject: "New Service Request for " + property.address,
            user_email: landlord.email,
            from_name: tenant.first_name + " " + tenant.last_name,
            message_body: `A new service request has been created for ${property.address} with the following details.\n\nDate Issue Occured: ${serviceRequest.date_issue_occured}\nSeverity of Issue: ${serviceRequest.severity_of_issue}\nType of Issue: ${serviceRequest.type_of_issue}\nMessage: ${serviceRequest.message}`,
          },
          process.env.REACT_APP_USER_ID_EMAILJS!
        );
        alert("Service request was succesfully created");
      } else {
        alert(
          "There was an error creating the service request, please try again or contact support."
        );
      }
    } else {
      alert("Please complete the entire form!");
    }
    handleClearFieldsServiceRequestModal();
    handleCloseServiceRequestModal();
  };

  return (
    <Layout
      protectedRoute={true}
      body={
        <Container>
          <h1 className="text-center pt-5">Tenant Dashboard</h1>

          {/* Make Service Request Modal */}
          <Modal
            show={showServiceRequestModal}
            onHide={handleCloseServiceRequestModal}
            className="modal-font">
            <Modal.Header closeButton>
              <Modal.Title>Create Service Request Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                An email will be sent to your landlord regarding the issue
                mentioned in this form.
              </p>
              <Form.Group className="mt-2">
                <Form.Label>Type of Issue</Form.Label>
                <Form.Control
                  as="select"
                  value={serviceRequest.type_of_issue}
                  onChange={(e) =>
                    setServiceRequest({
                      ...serviceRequest,
                      type_of_issue: e.target.value,
                    })
                  }>
                  <option value="">Select an issue</option>
                  <option value="Plumbing">Plumbing</option>
                  <option value="Power">Power</option>
                  <option value="Furniture/Appliance Related">
                    Furniture/Appliance Related
                  </option>
                  <option value="Internet">Internet</option>
                  <option value="Other">Other</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Label>When did this issue first occur?</Form.Label>
                <Form.Control
                  type="date"
                  value={serviceRequest.date_issue_occured}
                  onChange={(e) =>
                    setServiceRequest({
                      ...serviceRequest,
                      date_issue_occured: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Label>Severity of Issue</Form.Label>
                <Form.Control
                  as="select"
                  value={serviceRequest.severity_of_issue}
                  onChange={(e) =>
                    setServiceRequest({
                      ...serviceRequest,
                      severity_of_issue: e.target.value,
                    })
                  }>
                  <option value="">Select a severity level</option>
                  <option value="Normal">Normal</option>
                  <option value="Important">Important</option>
                  <option value="Urgent">Urgent</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Describe your issue"
                  value={serviceRequest.message}
                  onChange={(e) =>
                    setServiceRequest({
                      ...serviceRequest,
                      message: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                id="buttons"
                variant="secondary"
                onClick={handleCloseServiceRequestModal}>
                Close
              </Button>
              <Button
                id="buttons"
                variant="primary"
                onClick={handleSubmitFormServiceRequest}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>

          <Row className="pt-5 justify-content-center">
            <ListGroup variant="dark" className="w-50">
              <ListGroup.Item>{property.address}</ListGroup.Item>
              <ListGroup.Item>
                From <span id="text-highlight">{tenant.move_in_date}</span> to{" "}
                <span id="text-highlight">{tenant.move_out_date}</span>
              </ListGroup.Item>
              <ListGroup.Item>
                Amount Due:{" "}
                <span id="text-highlight">
                  $
                  {(
                    tenant.rent -
                    (tenant.rent_most_recent_payment_amount
                      ? tenant.rent_most_recent_payment_amount
                      : 0)
                  ).toLocaleString()}
                </span>
              </ListGroup.Item>
            </ListGroup>
          </Row>
          <Row className="justify-content-around">
            <Button
              id="buttons"
              variant="outline-dark"
              className="mt-5 mb-5 w-25">
              Make Payment
            </Button>
            <Button
              id="buttons"
              variant="outline-dark"
              className="mb-5 mt-5 w-25"
              onClick={handleShowServiceRequestModal}>
              Make Service Request
            </Button>
          </Row>
          <div>
            <Form.Check
              type="switch"
              label="View Announcements"
              checked={viewAnnouncementsTable}
              onChange={() => handleTableSelections(false, true)}
            />
            <Form.Check
              type="switch"
              label="Payments History"
              checked={viewPaymentsTable}
              onChange={() => handleTableSelections(true, false)}
            />
          </div>
          {viewAnnouncementsTable ? (
            <DataTable
              columns={columnsAnnouncements}
              data={announcements}
              className="mt-5"
              pagination
            />
          ) : null}
        </Container>
      }
    />
  );
};

export default TenantDashboardPage;

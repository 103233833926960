import { useContext, useEffect, useState } from "react";
import { LayoutProps } from "../../utils/interfaces";
import Footer from "../footer/footer";
import "./index.scss";
import { AuthContext } from "../../hooks/auth-context";
import JaskNavbar from "../jask-navbar/jask-navbar";

const Layout: React.FC<LayoutProps> = ({ body, protectedRoute }) => {
  const user = useContext(AuthContext);
  const [showContent, setShowContent] = useState<boolean>(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (protectedRoute) {
      if (user?.user) {
        setShowContent(true);
      } else {
        setShowContent(false);
      }
    } else {
      setShowContent(true);
    }
  }, [user]);
  return (
    <div className="background">
      <JaskNavbar />
      <div className="wrapper">
        {showContent ? body : <h1 className="center">You are not logged in</h1>}
      </div>
      <Footer />
    </div>
  );
};
export default Layout;

import React, { useState } from "react";
import { Layout } from "../../components";
import { Container, Form, Button } from "react-bootstrap";
import "./index.scss";
import { createLandlord, deleteUser } from "../../supabase/auth-functions";
import { addLandlordToTable } from "../../supabase/database-functions";
import { Landlord } from "../../utils/interfaces";
import { useNavigate } from "react-router-dom";
import { ReusableText } from "../../utils/reusable-text";

const SignUpPage: React.FC = () => {
  const [landlord, setLandlord] = useState<Landlord>({
    first_name: "",
    last_name: "",
    email: "",
    user_uid: "",
    phone_number: "",
    number_properties: 0,
  });
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // If the user completed all parts of the form
    if (
      landlord.first_name &&
      landlord.last_name &&
      landlord.email &&
      landlord.phone_number &&
      password
    ) {
      // Attempt to create the user
      const user = await createLandlord(landlord, password);
      // If the user is created
      if (user) {
        const updatedLandlord = {
          ...landlord,
          user_uid: user.id,
        };
        // Attempt to add the landlord to the database table
        const add_to_table = await addLandlordToTable(updatedLandlord);
        // If the landlord is added to the table -> redirect to landlord dashboard
        if (add_to_table) {
          navigate("/landlord-dashboard");
        }
        // Otherwise -> delete the user and display an error message
        else {
          await deleteUser(user.id);
          alert(
            "There was an error creating your account, please try again or contact support."
          );
        }
      }
      // Otherwise if the user is not created for some reason -> display an error message
      else {
        alert(
          "There was an unexpected error creating your account. Please try again or contact support."
        );
      }
    }
    // Otherwise if the form is not completed properly
    else {
      alert(ReusableText.incompleteForm);
    }
  };

  return (
    <Layout
      protectedRoute={false}
      body={
        <Container>
          <div className="center">
            <h1 className="text-center">Get Started with JASK</h1>
            <h4 className="text-center">Property Management</h4>
            <Form id="form-body" onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter first name"
                  value={landlord.first_name}
                  onChange={(e) =>
                    setLandlord({ ...landlord, first_name: e.target.value })
                  }
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter last name"
                  value={landlord.last_name}
                  onChange={(e) =>
                    setLandlord({ ...landlord, last_name: e.target.value })
                  }
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={landlord.email}
                  onChange={(e) =>
                    setLandlord({ ...landlord, email: e.target.value })
                  }
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter phone number"
                  value={landlord.phone_number}
                  onChange={(e) =>
                    setLandlord({ ...landlord, phone_number: e.target.value })
                  }
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Button
                variant="outline-dark"
                className="w-100"
                type="submit"
                id="buttons">
                Let's Get Started
              </Button>
            </Form>
          </div>
        </Container>
      }
    />
  );
};

export default SignUpPage;

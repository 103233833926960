export const token_generator = (prefix:string):string => {
const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-";
  let token = prefix + "-";
  const charactersLength = characters.length;

  for (let i = 0; i < 100; i++) {
    token += characters.charAt(
      Math.floor(Math.random() * charactersLength)
    );
  }
  return token
}
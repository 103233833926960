import React, { useEffect, useState } from "react";
import { Layout } from "../../components";
import { Container, Form, Button } from "react-bootstrap";
import { loginUser } from "../../supabase/auth-functions";
import { useNavigate } from "react-router-dom";

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const user = await loginUser(email, password);
    if (user) {
      if (user?.user_metadata.isLandlord) {
        navigate("/landlord-dashboard");
      } else {
        navigate("/tenant-dashboard");
      }
    } else {
      alert("Invalid login credentials");
    }
  };

  return (
    <Layout
      protectedRoute={false}
      body={
        <Container>
          <div className="center">
            <h1 className="text-center">Welcome to JASK</h1>
            <h4 className="text-center">Property Management</h4>
            <Form id="form-body" onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Form.Text className="text-muted hide">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Button
                id="buttons"
                variant="outline-dark"
                className="w-100"
                type="submit">
                Submit
              </Button>
            </Form>
          </div>
        </Container>
      }
    />
  );
};
export default LoginPage;

import React, { useEffect, useState, useRef, useContext } from "react";
import { AnnouncementModalComponent, Layout } from "../../components";
import {
  Button,
  Container,
  Row,
  Card,
  ListGroup,
  Modal,
  Form,
} from "react-bootstrap";
import {
  Libraries,
  StandaloneSearchBox,
  useJsApiLoader,
} from "@react-google-maps/api";
import { Landlord, PropertyInformation } from "../../utils/interfaces";
import {
  addProperty,
  deleteProperty,
  getLandlordInformation,
  getPropertiesUnderUser,
  updateLandlordInformation,
} from "../../supabase/database-functions";
import { AuthContext } from "../../hooks/auth-context";
import { useNavigate } from "react-router-dom";
import { token_generator } from "../../utils/token_generator";

import "./index.scss";

const libraries: Libraries = ["places"];

const LandlordDashboardPage: React.FC = () => {
  const user = useContext(AuthContext);
  const inputRef = useRef<any>(null);
  const navigate = useNavigate();

  // For Google Maps API
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
    libraries: libraries,
  });

  const [showAddPropertyModal, setShowAddPropertyModal] =
    useState<boolean>(false);
  const [landlord, setLandlord] = useState<Landlord>({
    first_name: "",
    last_name: "",
    email: "",
    user_uid: "",
    phone_number: "",
    number_properties: 0,
  });

  const [propertyAdded, setPropertyAdded] = useState<number>(0);
  const [propertyInformationAdd, setPropertyInformationAdd] =
    useState<PropertyInformation>({
      address: "",
      tenants: [],
      landlord_uid: "",
      total_rent: null,
      property_token: "",
    });

  const [properties, setProperties] = useState<PropertyInformation[]>([]);

  // Functions

  const fetchProperties = async () => {
    const propertyList = await getPropertiesUnderUser(landlord.user_uid!);
    if (propertyList) {
      setProperties(propertyList);
    }
  };

  const fetchData = async () => {
    if (user?.user?.id) {
      try {
        const landlordInfo = await getLandlordInformation(user.user.id);
        if (landlordInfo) {
          setLandlord(landlordInfo);
        }

        const propertyList = await getPropertiesUnderUser(user.user.id);
        if (propertyList) {
          setProperties(propertyList);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  // Load landlord and properties data when the user loads
  useEffect(() => {
    fetchData();
  }, [user]); // Fetch data when the user is available

  // Generate a new token initially and each time a property is added
  useEffect(() => {
    const token = token_generator("property");
    setPropertyInformationAdd({
      ...propertyInformationAdd,
      property_token: token,
    });
  }, [propertyAdded]);

  // Handles the user entering in an address on the Add Property Modal
  const handleOnPlacesChanged = () => {
    const address = inputRef.current.getPlaces();
    setPropertyInformationAdd({
      ...propertyInformationAdd,
      address: address[0]["formatted_address"],
      landlord_uid: user?.user!.id!,
    });
  };

  // Opening/Closing the Add Property Modal
  const handleShowAddPropertyModal = () => setShowAddPropertyModal(true);
  const handleCloseAddPropertyModel = () => setShowAddPropertyModal(false);

  // Handling the submission of the form on the Add Property Modal
  const handleSubmitFormAddPropertyModel = async () => {
    // If the user did not enter anything
    if (propertyInformationAdd.address == "") {
      alert("Please complete the form before submitting!");
      return;
    }

    // If the user entered a valid address

    // Update the number of properties managed by the landlord
    const updatedLandlord: Landlord = {
      ...landlord,
      number_properties: landlord.number_properties + 1,
    };
    const oldLandlord = landlord;
    setLandlord(updatedLandlord);

    // Attempt to update the data
    const updateLandlordData = await updateLandlordInformation(updatedLandlord);

    // Add the unique property token
    const submitProperty = await addProperty(propertyInformationAdd);

    // If data is updated
    if (submitProperty && updateLandlordData) {
      setPropertyAdded(propertyAdded + 1);
      const updatedProperties = await getPropertiesUnderUser(user?.user?.id!);
      setProperties(updatedProperties);
      alert(
        "Property: " +
          propertyInformationAdd.address +
          " has successfully been added!"
      );
      setShowAddPropertyModal(false);
    }
    // If data is not updated
    else {
      await updateLandlordInformation(oldLandlord);
      await deleteProperty(propertyInformationAdd.property_token);
      setLandlord(oldLandlord);
      alert(
        "An error has occurred adding your property, please try again or contact support"
      );
    }
  };

  const handleViewProperty = (property: PropertyInformation) => {
    // Navigate to view property page and pass property obj as props to that page
    navigate("/view-property", { state: { property, landlord } });
  };

  const handleDeleteProperty = async (property_token: string) => {
    const res = await deleteProperty(property_token);
    const updatedLandlord: Landlord = {
      ...landlord,
      number_properties: landlord.number_properties - 1,
    };
    const res2 = await updateLandlordInformation(updatedLandlord);
    if (res && res2) {
      await fetchProperties();
      alert("Property has been deleted");
    } else {
      alert("An unexpected error has occured during the deletion process");
    }
  };

  return (
    <Layout
      protectedRoute={true}
      body={
        <Container>
          <h1 className="text-center pt-5">Landlord Dashboard</h1>
          <div id="properties">
            <Button
              id="buttons"
              className="w-100 mb-5"
              variant="outline-dark"
              onClick={handleShowAddPropertyModal}>
              Add Property
            </Button>
            <AnnouncementModalComponent
              user_id={user?.user?.id!}
              show_all_properties={true}
              property_token_if_show_only_one_option=""
            />

            {/* Add Property Modal */}
            <Modal
              show={showAddPropertyModal}
              onHide={handleCloseAddPropertyModel}
              className="modal-font">
              <Modal.Header closeButton>
                <Modal.Title>Add Property Form</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mt-2">
                  <Form.Label>Property Address</Form.Label>
                  {isLoaded && (
                    <StandaloneSearchBox
                      onLoad={(ref) => (inputRef.current = ref)}
                      onPlacesChanged={handleOnPlacesChanged}>
                      <Form.Control
                        type="text"
                        placeholder="Enter address"
                        required
                      />
                    </StandaloneSearchBox>
                  )}
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  id="buttons"
                  variant="secondary"
                  onClick={handleCloseAddPropertyModel}>
                  Close
                </Button>
                <Button
                  id="buttons"
                  variant="primary"
                  onClick={handleSubmitFormAddPropertyModel}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Display properties */}
            <Row className="justify-content-around">
              {properties.map(
                (property: PropertyInformation, index: number) => (
                  <Card
                    bg="dark"
                    key={index}
                    text="light"
                    style={{ width: "20rem" }}
                    className="mb-2">
                    <Card.Body>
                      <Card.Title id="card_title">
                        {property.address?.split(",")[0]}
                        <br />
                        {property.address?.substring(
                          property.address?.split(",")[0].length + 1,
                          property.address?.length
                        )}
                      </Card.Title>
                      <ListGroup>
                        <ListGroup.Item>
                          Number of tenants:{" "}
                          {property.tenants ? property.tenants.length : 0}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Expected Monthly Rent:{" "}
                          {property.total_rent ? property.total_rent : "N/A"}
                        </ListGroup.Item>
                      </ListGroup>
                      <Button
                        id="buttons"
                        className="mt-2 text-center w-100"
                        variant="outline-light"
                        onClick={() => {
                          handleViewProperty(property);
                        }}>
                        View Property
                      </Button>
                      <Button
                        id="buttons"
                        className="mt-2 text-center w-100"
                        variant="outline-danger"
                        onClick={() => {
                          handleDeleteProperty(property.property_token);
                        }}>
                        Delete Property
                      </Button>
                    </Card.Body>
                  </Card>
                )
              )}
            </Row>
          </div>
        </Container>
      }
    />
  );
};

export default LandlordDashboardPage;

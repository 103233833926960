"use server";

import { User, UserResponse } from "@supabase/supabase-js";
import { supabase, supabaseAdmin } from "./client";
import { IssuesForDevTeam, Landlord, Tenant } from "../utils/interfaces";
import { addIssueForDevTeam } from "./database-functions";

export const getUser = async():Promise<User | null> => {
    const user = await supabase.auth.getUser();
    return user.data.user;
}
export const signOutUser = async():Promise<boolean> => {
    const {error} = await supabase.auth.signOut();
    if (error) {
        const issue:IssuesForDevTeam = {
            issue: "Error when signing out user\n" + error.message,
            resolved: false
        }
        await addIssueForDevTeam(issue);
        return false;
    }
    return true;
}

export const deleteUser = async(user_id:string):Promise<boolean> => {
    const {error} = await supabaseAdmin.auth.admin.deleteUser(user_id);
    if (error) {
        console.log(error);
        const issue:IssuesForDevTeam = {
            issue: "Error when deleting user\n" + user_id + "\n" + error.message,
            resolved: false
        }
        await addIssueForDevTeam(issue);
        return false;
    }
    return true;
}

export const createLandlord = async (landlord:Landlord, password: string): Promise<User | null> => {

    const { data, error } = await supabase.auth.signUp({
        password: password,
        email: landlord.email,
        phone: landlord.phone_number,
        options: {
            data: {
                displayName: `${landlord.first_name}  ${landlord.last_name}`,
                isLandlord: true,
            }
        }
    });

    if (error) {
        console.error(error);
        const issue:IssuesForDevTeam = {
            issue: "Error when creating landlord\n" + landlord.first_name + " " + landlord.last_name + " | " + landlord.email + "\n" + error.message,
            resolved: false
        }
        await addIssueForDevTeam(issue);
        return null; 
    }
    

    return data.user;
}

export const createTenant = async(tenant:Tenant, password:string): Promise<User | null> => {

    const {data, error} = await supabase.auth.signUp({
        password: password,
        email: tenant.email,
        phone: tenant.phone_number,
        options: {
            data: {
                displayName: `${tenant.first_name} ${tenant.last_name}`,
                isLandlord: false
            }
        }
    })

    if (error) {
        console.error(error)
        const issue:IssuesForDevTeam = {
            issue: "Error when creating tenant\n" + tenant.first_name + " " + tenant.last_name + " | " + tenant.email + "\n" + error.message,
            resolved: false
        }
        await addIssueForDevTeam(issue);
        return null;
    }

    return data.user

}

export const loginUser = async(email:string, password:string): Promise<User | null> => {
    const {data, error} = await supabase.auth.signInWithPassword({
        email: email,
        password: password
    })

    if (error) {
        console.error(error)
        const issue:IssuesForDevTeam = {
            issue: "Error when logging in user\n" + email + "\n" + error.message,
            resolved: false
        }
        await addIssueForDevTeam(issue);
        return null;
    }
    return data.user
}

import React, { useContext, useEffect, useState } from "react";
import { AnnouncementModalComponent, Layout } from "../../components";
import { Button, Container, Row, Table, Modal, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
  Announcements,
  Landlord,
  PropertyInformation,
  ServiceRequest,
  Tenant,
} from "../../utils/interfaces";
import emailjs from "@emailjs/browser";
import { token_generator } from "../../utils/token_generator";
import {
  addRegistrationToken,
  deleteTenant,
  getAnnouncementsByPropertyToken,
  getPropertyByToken,
  getServiceRequestsByPropertyToken,
  getTenantsByPropertyToken,
  updateRent,
  updateServiceRequestStatus,
  updateTenantsList,
} from "../../supabase/database-functions";
import DataTable, {
  ExpanderComponentProps,
  TableColumn,
} from "react-data-table-component";
import "./index.scss";
import { deleteUser } from "../../supabase/auth-functions";

export const ViewPropertyPage: React.FC = () => {
  // Getting the current property we are looking at
  const location = useLocation();
  const landlord = location.state.landlord as Landlord;

  const [property, setProperty] = useState<PropertyInformation>(
    location.state.property
  );

  //
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [showKickModal, setShowKickModal] = useState<boolean>(false);

  const [inviteTenantEmail, setInviteTenantEmail] = useState<string>("");
  const [inviteTenantRent, setInviteTenantRent] = useState<number>(0);
  const [inviteTenantMoveInDate, setInviteTenantMoveInDate] =
    useState<string>("");
  const [inviteTenantMoveOutDate, setInviteTenantMoveOutDate] =
    useState<string>("");

  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [serviceRequests, setServiceRequests] = useState<ServiceRequest[]>([]);
  const [announcements, setAnnouncements] = useState<Announcements[]>([]);
  const [announcementAdded, setAnnouncementAdded] = useState<boolean>(false);

  const [selectedTenantsKick, setSelectedTenantsKick] = useState<{
    [key: string]: { rent: number; email: string; name: string };
  }>({});

  const [viewTenantTable, setViewTenantTable] = useState<boolean>(true);
  const [viewServiceRequestsTable, setViewServiceRequestsTable] =
    useState<boolean>(false);
  const [viewAnnouncementsTable, setViewAnnouncementsTable] =
    useState<boolean>(false);

  // Columns for the tenants table
  const columnsTenants: TableColumn<Tenant>[] = [
    {
      name: "Full Name",
      selector: (row) => row.first_name + " " + row.last_name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Phone Number",
      selector: (row) =>
        row.phone_number
          ? `${row.phone_number.slice(0, 3)}-${row.phone_number.slice(
              3,
              6
            )}-${row.phone_number.slice(6, row.phone_number.length)}`
          : "N/A",
    },
    {
      name: "Monthly Rent ($)",
      selector: (row) => (row.rent ? row.rent.toLocaleString() : 0),
    },
    {
      name: "Move In Date",
      selector: (row) => row.move_in_date,
    },
    {
      name: "Move Out Date",
      selector: (row) => row.move_out_date,
    },
  ];

  // Columns for the service request table
  const columnsServiceRequests: TableColumn<ServiceRequest>[] = [
    {
      name: "Type of Issue",
      selector: (row) => row.type_of_issue,
    },
    {
      name: "Severity",
      selector: (row) => row.severity_of_issue,
    },
    {
      name: "Date Issue Occured",
      selector: (row) => row.date_issue_occured,
    },
    {
      name: "Completed",
      selector: (row) => (row.completed ? "Fulfilled" : "Not Fulfilled"),
    },
  ];

  // Columns for the announcements table
  const columnsAnnouncements: TableColumn<Announcements>[] = [
    {
      name: "Message",
      selector: (row) => row.message,
    },
    {
      name: "Date Sent",
      selector: (row) => row.date_sent,
    },
  ];

  // functions

  const loadTenants = async () => {
    const tenants = await getTenantsByPropertyToken(property.property_token);
    setTenants(tenants);
  };
  const loadPropertyDetails = async () => {
    const propertyDetails: PropertyInformation | null =
      await getPropertyByToken(property.property_token);
    if (propertyDetails) {
      setProperty(propertyDetails);
    }
  };
  const loadServiceRequests = async () => {
    const data = await getServiceRequestsByPropertyToken(
      property.property_token
    );
    setServiceRequests(data);
  };

  const loadAnnouncements = async () => {
    const res = await getAnnouncementsByPropertyToken(property.property_token);
    if (res) {
      setAnnouncements(res.sort((a, b) => b.id! - a.id!));
    }
  };

  const handleCloseInviteModal = () => setShowInviteModal(false);
  const handleShowInviteModal = () => setShowInviteModal(true);

  const handleCloseKickModal = () => setShowKickModal(false);
  const handleShowKickModal = () => setShowKickModal(true);

  const handleSelectedTenantKick = (tenant: Tenant) => {
    setSelectedTenantsKick((prevSelectedTenants) => {
      const newSelectedTenants = { ...prevSelectedTenants };

      if (newSelectedTenants.hasOwnProperty(tenant.user_uid)) {
        delete newSelectedTenants[tenant.user_uid];
      } else {
        newSelectedTenants[tenant.user_uid] = {
          rent: tenant.rent,
          email: tenant.email,
          name: `${tenant.first_name} ${tenant.last_name}`,
        };
      }

      return newSelectedTenants;
    });
  };

  const handleSubmitTenantInviteForm = () => {
    // Check if fields are empty

    if (
      inviteTenantEmail == "" ||
      inviteTenantRent == 0 ||
      inviteTenantMoveInDate == "" ||
      inviteTenantMoveOutDate == ""
    ) {
      alert("Please complete the entire form!");
      return;
    }

    // Send the invite email to the tenant
    const registration_token = token_generator("registration");
    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID_EMAILJS!,
        process.env.REACT_APP_TENANT_REGISTRATION_TEMPLATE_ID_EMAILJS!,
        {
          to_email: inviteTenantEmail,
          from_name: landlord.first_name + " " + landlord.last_name,
          property_address: property.address,
          registration_token: registration_token,
        },
        process.env.REACT_APP_USER_ID_EMAILJS!
      )
      .then(async (result: any) => {
        // Add registration token to database
        const res = await addRegistrationToken(
          registration_token,
          landlord.user_uid,
          property.property_token,
          inviteTenantRent,
          inviteTenantMoveInDate,
          inviteTenantMoveOutDate
        );
        if (res) {
          alert("Invite has been sent to: " + inviteTenantEmail);
          setShowInviteModal(false);
        } else {
          console.log(res);
          alert(
            "An unexpected error has occured, please try again later or contact support. " +
              res
          );
        }
      })
      .catch((error: any) => {
        console.log(error);
        alert(
          "An unexpected error has occured, please try again later or contact support." +
            error
        );
      });
  };

  const handleSubmitTenantKickForm = async () => {
    if (!selectedTenantsKick) {
      alert("No tenants have been selected to kick");
      setShowKickModal(false);
      return;
    }
    for (const tenant_id in selectedTenantsKick) {
      const rent: number = selectedTenantsKick[tenant_id].rent;
      const email: string = selectedTenantsKick[tenant_id].email;
      const name: string = selectedTenantsKick[tenant_id].name;

      await deleteTenant(tenant_id);
      var cur_tenants_list: string[] = property.tenants!;
      var list_tenants = cur_tenants_list.filter(
        (id: string) => id != tenant_id
      );
      await updateTenantsList(property.property_token, list_tenants);
      var property_rent: number = property.total_rent! - rent;
      await updateRent(property.property_token, property_rent);
      await deleteUser(tenant_id);

      // Sending email to tenant letting them know they have been kicked
      emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID_EMAILJS!,
          process.env.REACT_APP_BASIC_TEMPLATE_ID_EMAILJS!,
          {
            to_email: email,
            from_name: "JASK",
            subject: `Notice of Removal from ${property.address}`,
            message_body: `Dear ${name},\n\nYou have been removed from the property at ${property.address}.\n\nBest wishes,\nThe JASK Team 👊`,
          },
          process.env.REACT_APP_USER_ID_EMAILJS!
        )
        .then(() => {
          console.log(`Email sent to ${name} (${email})`);
        })
        .catch(() => {
          console.log(`Error sending email to ${name} (${email})`);
        });
    }

    alert("Selected tenant(s) have been kicked");

    // Re-fetch property and tenant details
    await loadTenants();
    await loadPropertyDetails();
  };

  const handleTableSelections = (
    tenants_table: boolean,
    service_request_table: boolean,
    announcements_table: boolean
  ) => {
    if (tenants_table) {
      setViewTenantTable(true);
      setViewServiceRequestsTable(false);
      setViewAnnouncementsTable(false);
    } else if (service_request_table) {
      setViewTenantTable(false);
      setViewServiceRequestsTable(true);
      setViewAnnouncementsTable(false);
    } else if (announcements_table) {
      setViewTenantTable(false);
      setViewServiceRequestsTable(false);
      setViewAnnouncementsTable(true);
    }
  };

  // Expandable component for the tenants table
  const ExpandedComponentTenantTable: React.FC<
    ExpanderComponentProps<Tenant>
  > = ({ data }) => {
    const rent_paid: number =
      data.rent -
      (data.rent_most_recent_payment_amount
        ? data.rent_most_recent_payment_amount
        : 0);
    const notifyTenant = async () => {
      const property_data: PropertyInformation | null =
        await getPropertyByToken(data.property_token);
      if (property_data) {
        emailjs
          .send(
            process.env.REACT_APP_SERVICE_ID_EMAILJS!,
            process.env.REACT_APP_BASIC_TEMPLATE_ID_EMAILJS!,
            {
              to_email: data.email,
              from_name: "JASK",
              subject:
                "Missing Rent Payment Notification from " + property.address,
              message_body: `Hello ${
                data.first_name + " " + data.last_name
              },\n\nThis is an automated payment reminder triggered by your landlord.\n\nYou need to pay the pending amount of $${rent_paid.toLocaleString()} for your stay at ${
                property.address
              }.\n\nPlease navigate to the tenant dashboard page to make your payment as soon as possible.\n\nBest wishes,\nThe JASK Team 👊`,
            },
            process.env.REACT_APP_USER_ID_EMAILJS!
          )
          .then(() => {
            alert("Email reminder has been sent!");
          });
      } else {
        alert(
          "An error occurred when attempting to send out the reminder, please try again or contact support."
        );
      }
    };

    return (
      <div className="details mb-3 mt-3">
        <h5 className="mb-3">Details</h5>
        {rent_paid == 0 ? (
          <p id="paid">
            Rent has been paid in full as of{" "}
            {data.rent_most_recent_payment_date}
          </p>
        ) : (
          <p id="not-paid">
            Rent has not been paid, there is ${rent_paid.toLocaleString()} still
            pending to be paid. <br />
            <br />
            Click the below button if you wish to email the tenant regarding the
            missing amount.
          </p>
        )}
        {rent_paid == 0 ? null : (
          <Button variant="outline-dark" onClick={notifyTenant}>
            Notify Tenant
          </Button>
        )}
      </div>
    );
  };

  // Expandable component for the service request table
  const ExpandedComponentServiceRequestTable: React.FC<
    ExpanderComponentProps<ServiceRequest>
  > = ({ data }) => {
    const handleUpdateServiceRequestStatus = async () => {
      const res = await updateServiceRequestStatus(
        data.service_request_token,
        data.completed
      );
      if (res) {
        alert("Service request has been updated");
        loadServiceRequests();
      } else {
        alert(
          "An error has occured trying to update the service request status, please try again or reach out to support."
        );
      }
    };
    return (
      <div className="details mb-3 mt-3">
        <h5 className="mb-3">Details</h5>
        <p>{data.message}</p>
        <Button
          onClick={handleUpdateServiceRequestStatus}
          variant="outline-dark">
          {data.completed ? "Mark as Incomplete" : "Mark as Complete"}
        </Button>
      </div>
    );
  };

  useEffect(() => {
    loadTenants();
    loadServiceRequests();
    loadAnnouncements();
  }, []);

  useEffect(() => {
    loadAnnouncements();
  }, [announcementAdded]);

  useEffect(() => {
    console.log(property);
  }, [property]);

  return (
    <Layout
      protectedRoute={true}
      body={
        <Container>
          <h1 className="text-center pt-5">View Property</h1>
          <h4 className="text-center mt-2">{property.address}</h4>
          {/* Invite Tenant Modal */}
          <Modal
            show={showInviteModal}
            onHide={handleCloseInviteModal}
            className="modal-font">
            <Modal.Header closeButton>
              <Modal.Title>Invite Tenant Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group className="mt-2">
                <Form.Label>Tenant Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Tenant Email Address"
                  value={inviteTenantEmail}
                  onChange={(e) => setInviteTenantEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Label>Rent</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter the monthly rent"
                  onChange={(e) => setInviteTenantRent(Number(e.target.value))}
                  required
                />
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Label>Move In Date</Form.Label>
                <Form.Control
                  type="date"
                  onChange={(e) => setInviteTenantMoveInDate(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Label>Move Out Date</Form.Label>
                <Form.Control
                  type="date"
                  onChange={(e) => setInviteTenantMoveOutDate(e.target.value)}
                  required
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                id="buttons"
                variant="secondary"
                onClick={handleCloseInviteModal}>
                Close
              </Button>
              <Button
                id="buttons"
                variant="primary"
                onClick={handleSubmitTenantInviteForm}>
                Invite Tenant
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Invite Tenant Modal */}

          {/* Kick Tenant Modal */}
          <Modal
            show={showKickModal}
            onHide={handleCloseKickModal}
            className="modal-font">
            <Modal.Header closeButton>
              <Modal.Title>Kick Tenant Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Wrap the table in a container with scrollable styles */}
              <div id="kick-table-container">
                <Table
                  striped
                  bordered
                  hover
                  variant="light"
                  className="kick-table">
                  <thead>
                    <tr>
                      <th>Select</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Rent</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tenants.map((tenant: Tenant, index: number) => (
                      <tr key={index}>
                        <td>
                          <Form.Check
                            className="mt-3"
                            type="checkbox"
                            checked={selectedTenantsKick.hasOwnProperty(
                              tenant.user_uid
                            )}
                            onChange={() => handleSelectedTenantKick(tenant)}
                          />
                        </td>
                        <td>{tenant.first_name}</td>
                        <td>{tenant.last_name}</td>
                        <td>{tenant.email}</td>
                        <td>{tenant.rent}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                id="buttons"
                variant="secondary"
                onClick={handleCloseKickModal}>
                Close
              </Button>
              <Button
                id="buttons"
                variant="primary"
                onClick={handleSubmitTenantKickForm}>
                Kick
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Kick Tenant Modal */}

          <Row className="justify-content-around mt-5">
            <AnnouncementModalComponent
              show_all_properties={false}
              user_id={landlord.user_uid}
              property_token_if_show_only_one_option={property.property_token}
              callback_function_for_announcement_addition={setAnnouncementAdded}
            />
            <Button
              id="buttons"
              variant="outline-dark"
              className="w-25 mb-2 mt-2"
              onClick={handleShowInviteModal}>
              Invite Tenant
            </Button>
            <Button
              id="buttons"
              variant="outline-dark"
              className="w-25 mb-2 mt-2"
              onClick={handleShowKickModal}>
              Remove Tenant
            </Button>
          </Row>

          <div className="mt-5">
            <Form.Check
              type="switch"
              label="Tenant Table"
              checked={viewTenantTable}
              onChange={() => handleTableSelections(true, false, false)}
            />
            <Form.Check
              type="switch"
              label="Service Request Table"
              checked={viewServiceRequestsTable}
              onChange={() => handleTableSelections(false, true, false)}
            />
            <Form.Check
              type="switch"
              label="Announcements Table"
              checked={viewAnnouncementsTable}
              onChange={() => handleTableSelections(false, false, true)}
            />
          </div>

          {/* Displaying the tables */}
          {viewTenantTable ? (
            <DataTable
              columns={columnsTenants}
              data={tenants}
              expandableRows
              expandableRowsComponent={ExpandedComponentTenantTable}
              className="mt-5"
              pagination
            />
          ) : viewServiceRequestsTable ? (
            <DataTable
              columns={columnsServiceRequests}
              data={serviceRequests}
              expandableRows
              expandableRowsComponent={ExpandedComponentServiceRequestTable}
              className="mt-5"
              pagination
            />
          ) : (
            <DataTable
              columns={columnsAnnouncements}
              data={announcements}
              className="mt-5"
              pagination
            />
          )}
          {/*^^ Displaying the tables ^^*/}
        </Container>
      }
    />
  );
};
export default ViewPropertyPage;

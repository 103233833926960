import { Container } from "react-bootstrap";
import "./index.scss";
const Footer: React.FC = () => {
  return (
    <Container fluid className="text-center footer">
      <div className="pt-5 pb-3 text-center">
        <hr id="underline" />
        <p>© 2024 - JASK</p>
      </div>
    </Container>
  );
};
export default Footer;

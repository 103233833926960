// supabase/client.ts (Server-Side Supabase Client)
import { createClient } from '@supabase/supabase-js';
import { Database } from './database.types';


 const createSupabaseServerClient = () => {
  return createClient<Database>(
    process.env.REACT_APP_SUPABASE_URL!,
    process.env.REACT_APP_SUPABASE_KEY!
  );
};
const createSupabaseAdminServerClient = () => {
  return createClient<Database>(
    process.env.REACT_APP_SUPABASE_URL!,
    process.env.REACT_APP_SUPABSE_ANON_KEY!
  )
}
export const supabase = createSupabaseServerClient();
export const supabaseAdmin = createSupabaseAdminServerClient();
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./global.scss";
import "./fonts.scss";
import {
  LandlordDashboardPage,
  LoginPage,
  SignUpPage,
  TenantDashboardPage,
  TenantRegistrationPage,
  ViewPropertyPage,
} from "./pages";
import { AuthProvider } from "./hooks/auth-context";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/signin",
    element: <LoginPage />,
  },
  {
    path: "/signup",
    element: <SignUpPage />,
  },
  {
    path: "/landlord-dashboard",
    element: <LandlordDashboardPage />,
  },
  {
    path: "/tenant-dashboard",
    element: <TenantDashboardPage />,
  },
  {
    path: "/view-property",
    element: <ViewPropertyPage />,
  },
  {
    path: "/tenant-registration/:registration_token",
    element: <TenantRegistrationPage />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <AuthProvider>
    <RouterProvider router={router} />
  </AuthProvider>
);

import { Container, Nav, Navbar } from "react-bootstrap";
import "./index.scss";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../hooks/auth-context";
import { signOutUser } from "../../supabase/auth-functions";
import { useNavigate } from "react-router-dom";
const JaskNavbar: React.FC = () => {
  const user = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(user);
  }, [user]);

  const handleSignOut = async () => {
    const res = await signOutUser();
    if (res) {
      alert("You have been signed out");
      navigate("/login");
    } else {
      alert("An error has occured signing you out");
      navigate("/error");
    }
  };

  const handleSignIn = async () => {
    navigate("/login");
  };

  const handleDashboard = () => {
    if (user?.user?.user_metadata.isLandlord) {
      navigate("/landlord-dashboard");
    } else {
      navigate("/tenant-dashboard");
    }
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="logged-in-nav"
      variant="dark">
      <Container>
        <Navbar.Brand href="/">JASK</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/pricing">Pricing</Nav.Link>
          </Nav>
          {user?.user ? (
            <Nav>
              <Nav.Link onClick={handleDashboard}>Dashboard</Nav.Link>
              <Nav.Link eventKey={2} onClick={handleSignOut}>
                Sign Out
              </Nav.Link>
            </Nav>
          ) : (
            <Nav>
              <Nav.Link eventKey={2} onClick={handleSignIn}>
                Sign In
              </Nav.Link>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default JaskNavbar;

import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import {
  AnnouncementModal,
  Announcements,
  PropertyInformation,
  Tenant,
} from "../../utils/interfaces";
import {
  addAnnouncement,
  getPropertiesUnderUser,
  getPropertyByToken,
  getTenantsByPropertyToken,
} from "../../supabase/database-functions";
import emailjs from "@emailjs/browser";

const AnnouncementModalComponent: React.FC<AnnouncementModal> = ({
  user_id,
  show_all_properties,
  property_token_if_show_only_one_option,
  callback_function_for_announcement_addition,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<PropertyInformation[]>(
    []
  );
  const [options, setOptions] = useState<PropertyInformation[]>([]);
  const [message, setMessage] = useState<string>("");

  const [selectAll, setSelectAll] = useState<boolean>(false);

  const fetchOptions = async () => {
    if (show_all_properties) {
      const properties: PropertyInformation[] = await getPropertiesUnderUser(
        user_id
      );
      if (properties) {
        setOptions(properties);
      } else {
        alert(
          "There was an error fetching the properties under your user, please try again or contact support."
        );
      }
    } else {
      const property: PropertyInformation | null = await getPropertyByToken(
        property_token_if_show_only_one_option
      );
      if (property) {
        setOptions([property]);
      } else {
        alert(
          "There was an error fetching the properties under your user, please try again or contact support."
        );
      }
    }
  };

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleSubmit = async () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    const date = `${year}-${month}-${day}`;

    const property_tokens: string[] = [];

    if (show_all_properties) {
      options.forEach((option) => {
        if (selectAll || selectedOptions.includes(option)) {
          property_tokens.push(option.property_token);
        }
      });
    } else {
      property_tokens.push(property_token_if_show_only_one_option);
    }

    // Send an email to all tenants
    for (const option of options) {
      const tenants: Tenant[] = await getTenantsByPropertyToken(
        option.property_token
      );

      for (const tenant of tenants) {
        await emailjs.send(
          process.env.REACT_APP_SERVICE_ID_EMAILJS!,
          process.env.REACT_APP_BASIC_TEMPLATE_ID_EMAILJS!,
          {
            to_email: tenant.email,
            from_name: "JASK",
            subject: `Announcement from ${option.address}`,
            message_body: `Dear ${tenant.first_name} ${tenant.last_name},\n\nYour landlord made the following announcement for all tenants living at ${option.address}.\n\n${message}\n\nBest wishes,\nThe JASK Team 👊`,
          },
          process.env.REACT_APP_USER_ID_EMAILJS!
        );
      }
    }

    const announcement: Announcements = {
      message: message,
      property_tokens: property_tokens,
      date_sent: date,
    };
    const res = await addAnnouncement(announcement);
    if (res) {
      alert("Announcement has been added and sent to tenants via email!");
      if (callback_function_for_announcement_addition) {
        callback_function_for_announcement_addition(true);
      }

      // Clear fields
      setShow(false);
      setSelectedOptions([]);
      setMessage("");
      setSelectAll(false);
    } else {
      alert(
        "There was an error sending your announcement, please try again or contact support."
      );
    }
  };

  const handleOnOptionCheck = (option: PropertyInformation) => {
    setSelectedOptions((prevSelectedOptions) => {
      let newSelectedOptions = [...prevSelectedOptions];

      if (newSelectedOptions.includes(option)) {
        newSelectedOptions = newSelectedOptions.filter(
          (selectedOption) => selectedOption !== option
        );
      } else {
        newSelectedOptions.push(option);
      }

      return newSelectedOptions;
    });
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  return (
    <div>
      <Button
        onClick={handleShow}
        id="buttons"
        className="w-100 mb-5"
        variant="outline-dark">
        Make Announcement
      </Button>
      <Modal show={show} onHide={handleClose} className="modal-font">
        <Modal.Header closeButton>
          <Modal.Title>Make Announcement Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mt-2">
            <Form.Label>
              {show_all_properties
                ? "Select the Properties you want to notify"
                : options[0]
                ? options[0].address
                : "NA"}
            </Form.Label>
            {show_all_properties ? (
              <Table
                striped
                bordered
                hover
                variant="light"
                className="kick-table">
                <thead>
                  <tr>
                    <th>Select</th>
                    <th>Property</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Check
                        className="mt-3"
                        type="checkbox"
                        checked={selectAll}
                        onChange={() => setSelectAll(!selectAll)}
                      />
                    </td>
                    <td>Select All</td>
                  </tr>
                  {options.map((option: PropertyInformation, index: number) => (
                    <tr key={index}>
                      <td>
                        <Form.Check
                          className="mt-3"
                          type="checkbox"
                          checked={
                            selectAll ? true : selectedOptions.includes(option)
                          }
                          onChange={() => handleOnOptionCheck(option)}
                        />
                      </td>
                      <td>{option.address}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
            <Form.Group className="mt-2">
              {" "}
              <Form.Label>Announcement</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Describe your announcement"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Group>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button id="buttons" variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button id="buttons" variant="primary" onClick={handleSubmit}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default AnnouncementModalComponent;

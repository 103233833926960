"use server"

import { User } from "@supabase/supabase-js";
import { supabase } from "./client";
import { Announcements, IssuesForDevTeam, Landlord, PropertyInformation, RegistrationTokenInformation, ServiceRequest, Tenant } from "../utils/interfaces";





// ---------------------------------------------------------------------------
// Adder functions

export const addLandlordToTable = async(landlord:Landlord):Promise<boolean> => {


    const {error} = await supabase.from("landlords").insert(
    
        {
        first_name: landlord.first_name,
        last_name: landlord.last_name,
        email: landlord.email,
        user_uid: landlord.user_uid,
        phone_number: landlord.phone_number,
        number_properties: landlord.number_properties
        }
    )

    if (error) {
        console.log(error);
        const issue:IssuesForDevTeam = {
            issue: "Error when adding landlord to table\n" + landlord.first_name + " " + landlord.last_name + "\n" + error.message,
            resolved: false
        }
        await addIssueForDevTeam(issue);
        return false;
    }
    return true;

}

export const addTenantToTable = async (tenant:Tenant):Promise<boolean> => {
    const {error} = await supabase.from("tenants").insert({
        first_name: tenant.first_name,
        last_name: tenant.last_name,
        email: tenant.email,
        phone_number: tenant.phone_number,
        move_in_date: tenant.move_in_date,
        move_out_date: tenant.move_out_date,
        rent: tenant.rent,
        rent_most_recent_payment_date: tenant.rent_most_recent_payment_date,
        rent_most_recent_payment_amount: tenant.rent_most_recent_payment_amount,
        user_uid: tenant.user_uid,
        property_token: tenant.property_token
    })
    if (error) {
        console.log(error);
        const issue:IssuesForDevTeam = {
            issue: "Error when adding tenant to table under property\n" + tenant.property_token + "\n" + error.message,
            resolved: false
        }
        await addIssueForDevTeam(issue);
        return false;
    }
    return true;
}

export const addProperty = async(propertyInformation: PropertyInformation):Promise<boolean> => {
    const {error} = await supabase.from("properties").insert(
        {
            address: propertyInformation.address,
            tenants: propertyInformation.tenants ? propertyInformation.tenants.join(",") : "",
            landlord_uid: propertyInformation.landlord_uid,
            property_token: propertyInformation.property_token,
        }
    )
    if (error) {
        console.log(error);
        const issue:IssuesForDevTeam = {
            issue: "Error when adding property to table for landlord\n" + propertyInformation.landlord_uid + "\n" + error.message,
            resolved: false
        }
        await addIssueForDevTeam(issue);
        return false;
    }
    return true;
}

export const addRegistrationToken = async(token:string, landlord_uid:string, property_token:string, rent:number, move_in_date:string, move_out_date:string):Promise<boolean> => {
    const {error} = await supabase.from("registration_tokens").insert(
        {
            registration_token: token,
            landlord_uid: landlord_uid,
            property_token: property_token,
            rent: rent,
            move_in_date: move_in_date,
            move_out_date: move_out_date
        }
    )
    if (error) {
        console.log(error);
        const issue:IssuesForDevTeam = {
            issue: "Error when adding registration token to table\n" + error.message,
            resolved: false
        }
        await addIssueForDevTeam(issue);
        return false;
    }
    return true;
}

export const addServiceRequest = async(service_request:ServiceRequest):Promise<boolean> => {
    const {error} =  await supabase.from("service_requests").insert(
        {
            type_of_issue: service_request.type_of_issue,
            severity_of_issue: service_request.severity_of_issue,
            date_issue_occured: service_request.date_issue_occured,
            message: service_request.message,
            property_token: service_request.property_token,
            service_request_token: service_request.service_request_token,
            completed: service_request.completed
        }
    )
    if (error) {
        console.log(error);
        const issue:IssuesForDevTeam = {
            issue: "Error when adding service request to table\n" + service_request.service_request_token + "\n" +error.message,
            resolved: false
        }
        await addIssueForDevTeam(issue);
        return false;
    }
    return true;
}

export const addAnnouncement = async(announcement:Announcements):Promise<boolean> => {
    const {error} = await supabase.from("announcements").insert({
        message: announcement.message,
        date_sent: announcement.date_sent,
        property_tokens: announcement.property_tokens.join(",")
    });
    if (error) {
        console.log(error)
        const issue:IssuesForDevTeam = {
            issue: "Error when adding announcement to table for property\n" + announcement.property_tokens + "\n" + error.message,
            resolved: false
        }
        await addIssueForDevTeam(issue);
        return false;
    }
    return true;
}

export const addIssueForDevTeam = async(issue:IssuesForDevTeam):Promise<boolean> => {
    const {error} = await supabase.from("issues_for_dev_team").insert(issue);
    if (error) {
        console.log(error);
        // send email to dev team
        return false;
    }
    return true;
}

// ---------------------------------------------------------------------------
// Getter functions

export const getAnnouncementsByPropertyToken = async(property_token:string):Promise<Announcements[]> => {
    const {data, error} = await supabase.from("announcements").select("*");
    if (error) {
        console.log(error);
        const issue:IssuesForDevTeam = {
            issue: "Error when getting announcement by property token\n" + property_token + "\n" + error.message,
            resolved: false
        }
        await addIssueForDevTeam(issue);
        return [];
    }
    const announcements:Announcements[] = [];
    data.forEach(announcement => {
        const tokens:string[] = announcement.property_tokens.split(",");
        if (tokens.includes(property_token)) {
            announcements.push({
                message: announcement.message!,
                date_sent: announcement.date_sent!,
                property_tokens: tokens,
                id: announcement.id
            })
        }
    })
    return announcements;
}

export const getServiceRequestsByPropertyToken = async(property_token:string):Promise<ServiceRequest[]> => {
    const {data, error} = await supabase.from("service_requests").select("*").eq("property_token", property_token);
    if (error) {
        console.log(error)
        const issue:IssuesForDevTeam = {
            issue: "Error when getting service request by property token\n" + property_token + "\n" + error.message,
            resolved: false
        }
        await addIssueForDevTeam(issue);
        return []
    }
    const service_requests:ServiceRequest[] = [];
    data.forEach(service_request => {
        service_requests.push({
            type_of_issue: service_request.type_of_issue!,
            severity_of_issue: service_request.severity_of_issue!,
            date_issue_occured: service_request.date_issue_occured!,
            message: service_request.message!,
            property_token: service_request.property_token!,
            service_request_token: service_request.service_request_token!,
            completed: service_request.completed!
        })
    })
    return service_requests
}

export const getPropertiesUnderUser = async(landlord_uid:string):Promise<PropertyInformation[]> => {

    const {data, error} = await supabase.from("properties").select("*").eq("landlord_uid", landlord_uid);
    
    if (error) {
        console.log(error)
        const issue:IssuesForDevTeam = {
            issue: "Error when getting property under user\n" + landlord_uid + "\n" + error.message,
            resolved: false
        }
        await addIssueForDevTeam(issue);
        return [];
    }
    const properties:PropertyInformation[] = [];
    data.forEach(property => {
        properties.push({
            address: property.address!,
            tenants: property.tenants ? property.tenants.split(",") : null,
            landlord_uid: property.landlord_uid!,
            total_rent: property.total_rent,
            property_token: property.property_token!
        })
    });

    return properties

}

export const getLandlordInformation = async(landlord_uid:string):Promise<Landlord> => {
    const {data, error} = await supabase.from("landlords").select("*").eq("user_uid", landlord_uid);
    
    var landlord:Landlord = {
        first_name: "",
        last_name: "",
        email: "",
        user_uid: landlord_uid,
        phone_number: "",
        number_properties: 0
    }

    if (error) {
        console.log(error)
        const issue:IssuesForDevTeam = {
            issue: "Error when getting landlord information for\n" + landlord_uid + "\n" + error.message,
            resolved: false
        }
        await addIssueForDevTeam(issue);
        return landlord
    }
    console.log(data)
    landlord = {
        first_name: data[0].first_name,
        last_name: data[0].last_name!,
        email: data[0].email!,
        user_uid: landlord_uid,
        phone_number: data[0].phone_number!,
        number_properties: data[0].number_properties!
    }
    return landlord;
}

export const getRegistrationTokenDetails = async (registration_token:string):Promise<RegistrationTokenInformation | null> => {

    const {data, error} = await supabase.from("registration_tokens").select("*").eq("registration_token", registration_token);
    
    if (error) {
        console.log(error);
        const issue:IssuesForDevTeam = {
            issue: "Error when getting registration token details for\n" + registration_token + "\n" + error.message,
            resolved: false
        }
        await addIssueForDevTeam(issue);
        return null;
    }
    if (data[0] == null || data[0] == undefined || error) {
        console.log(data);
        console.log(error)
        return null;
    }
    return {
        landlord_uid: data[0].landlord_uid!,
        property_token: data[0].property_token!,
        registration_token: data[0].registration_token!,
        rent: data[0].rent!,
        move_in_date: data[0].move_in_date,
        move_out_date: data[0].move_out_date
    }
}

export const getPropertyByToken = async (property_token:string):Promise<PropertyInformation | null> => {
    const {data, error} = await supabase.from("properties").select("*").eq("property_token", property_token);
    if (error) {
        console.log(error);
        const issue:IssuesForDevTeam = {
            issue: "Error when getting property details by property token\n" + property_token + "\n" + error.message,
            resolved: false
        }
        await addIssueForDevTeam(issue);
        return null;
    }
    return {
        address: data[0].address!,
        tenants: data[0].tenants == null ? null : data[0].tenants.split(","),
        landlord_uid: data[0].landlord_uid!,
        total_rent: data[0].total_rent,
        property_token: data[0].property_token!
    }
}

export const getTenantsByPropertyToken = async (property_token:string):Promise<Tenant[]> => {
    const {data, error} = await supabase.from("tenants").select("*").eq("property_token", property_token);
    if (error) {
        console.log(error);
        const issue:IssuesForDevTeam = {
            issue: "Error when getting tenants by property token\n" + property_token + "\n" + error.message,
            resolved: false
        }
        await addIssueForDevTeam(issue);
        return [];
    }
    const tenants:Tenant[] = [];
    data.forEach(tenant => {
        tenants.push({
            first_name: tenant.first_name!,
            last_name: tenant.last_name!,
            email: tenant.email!,
            phone_number: tenant.phone_number!,
            move_in_date: tenant.move_in_date!,
            move_out_date: tenant.move_out_date!,
            rent: tenant.rent!,
            rent_most_recent_payment_date: tenant.rent_most_recent_payment_date,
            rent_most_recent_payment_amount: tenant.rent_most_recent_payment_amount,
            property_token: tenant.property_token!,
            user_uid: tenant.user_uid!
        })
    })
    return tenants;
}

export const getTenantByUserID = async (id: string):Promise<Tenant> => {
    const {data, error} = await supabase.from("tenants").select("*").eq("user_uid", id);
    if (error) {
        console.log(error);
        const issue:IssuesForDevTeam = {
            issue: "Error when getting tenant by userID\n" + id + "\n" + error.message,
            resolved: false
        }
        await addIssueForDevTeam(issue);
        return {
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            move_in_date: "",
            move_out_date: "",
            rent: 0,
            rent_most_recent_payment_amount: 0,
            rent_most_recent_payment_date: "",
            user_uid: "",
            property_token: ""
        }
    }
    return {
        first_name: data[0].first_name,
        last_name: data[0].last_name!,
        email:data[0].email!,
        phone_number: data[0].phone_number!,
        move_in_date: data[0].move_in_date!,
        move_out_date: data[0].move_out_date!,
        rent: data[0].rent!,
        rent_most_recent_payment_amount: data[0].rent_most_recent_payment_amount,
        rent_most_recent_payment_date: data[0].rent_most_recent_payment_date,
        user_uid: data[0].user_uid!,
        property_token:data[0].property_token
    }
}

// ---------------------------------------------------------------------------
// Update functions

export const updateLandlordInformation = async(landlord:Landlord): Promise<boolean> => {
    const {error} = await supabase.from("landlords").update(landlord).eq("user_uid", landlord.user_uid!);
    if (error) {
        console.log(error)
        const issue:IssuesForDevTeam = {
            issue: "Error when updating landlord information for landlord \n" + landlord.first_name + " " + landlord.last_name + " | " + landlord.email + " | " + landlord.user_uid + "\n" + error.message,
            resolved: false
        }
        await addIssueForDevTeam(issue);
        return false;
    }
    return true;
}

export const updateTenantsList = async(property_token:string, tenants: string[]):Promise<boolean> => {
    var tenants_list:string;
    if (tenants.length == 1) {
        tenants_list = tenants[0]
    }
    else if (tenants.length == 0){
        tenants_list = "";
    }
    else {
        tenants_list = tenants.join(",")
    }
    const {error} = await supabase.from("properties").update({
        tenants: tenants_list
    }).eq("property_token", property_token);
    if (error) {
        console.log(error)
        const issue:IssuesForDevTeam = {
            issue: "Error when updating tenant list for property\n" + property_token + "\n" + error.message,
            resolved: false
        }
        await addIssueForDevTeam(issue);
        return false;
    }
    return true;
}

export const updateRent = async(property_token:string, rent:number):Promise<boolean> => {
    const {error} = await supabase.from("properties").update({
        total_rent: rent
    }).eq("property_token", property_token)
    if (error) {
        console.log(error);
        const issue:IssuesForDevTeam = {
            issue: "Error when updating rent of amount: " + rent + " for property\n" + property_token + "\n" + error.message,
            resolved: false
        }
        await addIssueForDevTeam(issue);
        return false;
    }
    return true;
}

export const updateServiceRequestStatus = async(service_request_token:string, current_completed_status:boolean):Promise<boolean> => {
    const {error} = await supabase.from("service_requests").update({
        completed: !current_completed_status
    }).eq("service_request_token", service_request_token)
    if (error) {
        console.log(error);
        const issue:IssuesForDevTeam = {
            issue: "Error when updating service request status for service request from " + current_completed_status + " to " + !current_completed_status + " for service request: \n" + service_request_token + "\n" + error.message,
            resolved: false
        }
        await addIssueForDevTeam(issue);
        return false;
    }
    return true;
}

// ---------------------------------------------------------------------------
// Delete functions

export const deleteProperty = async(property_token:string):Promise<boolean> => {
    const response = await supabase.from("properties").delete().eq("property_token", property_token);
    console.log(response)
    if (response) {
        return true;
    }
    const issue:IssuesForDevTeam = {
        issue: "Error when deleting property\n" + property_token,
        resolved: false
    }
    await addIssueForDevTeam(issue);
    return false;
}

export const deleteRegistrationToken = async(registration_token:string):Promise<boolean> => {
    const res = await supabase.from("registration_tokens").delete().eq("registration_token", registration_token)
    console.log(res);
    if (res) {
        return true;
    }
    const issue:IssuesForDevTeam = {
        issue: "Error when deleting registration token\n" + registration_token,
        resolved: false
    }
    await addIssueForDevTeam(issue);
    return false;
}

export const deleteTenant = async (tenant_uid:string):Promise<boolean> => {
    const res = await supabase.from("tenants").delete().eq("user_uid", tenant_uid);
    console.log(res);
    if (res) {
        return true;
    }
    const issue:IssuesForDevTeam = {
        issue: "Error when deleting tenant\n" + tenant_uid,
        resolved: false
    }
    await addIssueForDevTeam(issue);
    return false;
}


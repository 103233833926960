import React, { useEffect, useState } from "react";
import { Layout } from "../../components";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  addTenantToTable,
  deleteRegistrationToken,
  deleteTenant,
  getLandlordInformation,
  getPropertyByToken,
  getRegistrationTokenDetails,
  updateRent,
  updateTenantsList,
} from "../../supabase/database-functions";
import {
  Landlord,
  PropertyInformation,
  RegistrationTokenInformation,
  Tenant,
} from "../../utils/interfaces";

import "./index.scss";
import { createTenant, deleteUser } from "../../supabase/auth-functions";

const TenantRegistrationPage: React.FC = () => {
  const navigate = useNavigate();
  // Getting the registration token in the URL
  const { registration_token } = useParams<{ registration_token: string }>();

  const [validToken, setValidToken] = useState<boolean>(false);
  const [landlord, setLandlord] = useState<Landlord>({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    number_properties: 0,
    user_uid: "",
  });

  const [property, setProperty] = useState<PropertyInformation>({
    address: "",
    tenants: [],
    landlord_uid: "",
    total_rent: 0,
    property_token: "",
  });

  const [tokenInformation, setTokenInformation] =
    useState<RegistrationTokenInformation>({
      landlord_uid: "",
      property_token: "",
      registration_token: "",
      rent: 0,
      move_in_date: "",
      move_out_date: "",
    });

  const [tenantInformation, setTenantInformation] = useState<Tenant>({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    move_in_date: "",
    move_out_date: "",
    rent: 0,
    rent_most_recent_payment_amount: 0,
    rent_most_recent_payment_date: "",
    user_uid: "",
    property_token: "",
  });
  const [password, setPassword] = useState<string>("");
  const [certify, setCertify] = useState<boolean>(false);

  // Validating the registration token
  const checkToken = async () => {
    if (registration_token) {
      const res = await getRegistrationTokenDetails(registration_token);

      if (res) {
        setValidToken(true);
        setTokenInformation(res);
      }
    }
  };

  // Getting the details of the landlord who invited this user and the details of the property the user is being invited to
  const getLandlordAndPropertyDetails = async () => {
    const landlordDetails = await getLandlordInformation(
      tokenInformation.landlord_uid
    );
    if (!landlordDetails) {
      alert("Cannot find landlord details");
      setValidToken(false);
    } else {
      setLandlord(landlordDetails);
    }
    const propertyDetails = await getPropertyByToken(
      tokenInformation.property_token
    );
    if (!propertyDetails) {
      alert("Cannot find property details");
      setValidToken(false);
    } else {
      setProperty(propertyDetails);
    }
  };

  // Completing tenant registration
  const completeTenantRegistration = async () => {
    // Check if any field was left blank
    if (
      tenantInformation.first_name == "" ||
      tenantInformation.last_name == "" ||
      tenantInformation.email == "" ||
      tenantInformation.phone_number == "" ||
      password.length < 8 ||
      password.length > 16 ||
      !certify
    ) {
      alert("Please ensure you complete the entire form!");
    }

    // Create the user
    const tenantUser = await createTenant(tenantInformation, password);

    if (!tenantUser) {
      alert(
        "There was an issue creating your account, please try again or contact support."
      );
    } else {
      // Add the necessary info to the tenant object
      const updatedTenantInformation: Tenant = {
        ...tenantInformation,
        user_uid: tenantUser?.id!,
      };
      setTenantInformation(updatedTenantInformation);

      const addTenant = await addTenantToTable(updatedTenantInformation);

      // If we can add the tenant to the table
      if (addTenant) {
        // Attempt to delete the registration token
        const delete_token = await deleteRegistrationToken(
          tokenInformation.registration_token
        );
        // If we successfully delete the token
        if (delete_token) {
          // Attempt to add the user to the property's tenant's list
          var tenants_list: string[] = [];
          // Store the old list in case we need to remove the tenant as part of the error handling process
          const old_list: string[] = property.tenants ? property.tenants : [];

          if (
            property.tenants != null &&
            (property.tenants?.length == 0 || property.tenants[0] == "")
          ) {
            tenants_list = [tenantUser.id];
          } else if (property.tenants != null) {
            tenants_list = [...property.tenants, tenantUser.id];
          }
          // Attempt to add the tenant to the property's tenant list
          const update_list = await updateTenantsList(
            tokenInformation.property_token,
            tenants_list
          );
          // If we can successfully update the tenant's list
          if (update_list) {
            // Attempt to update the property's overall rent
            const update_rent = await updateRent(
              tokenInformation.property_token,
              (property.total_rent ? property.total_rent : 0) +
                tokenInformation.rent
            );
            // If we can update the property's overall rent -> redirect the user to the tenant dashboard
            if (update_rent) {
              alert("You have successfully been added to " + property.address);
              navigate("/tenant-dashboard");
            }
            // Otherwise delete the user from the tenant's table, delete the user, and remove them from the tenant's list
            else {
              await updateTenantsList(
                tokenInformation.property_token,
                old_list
              );
              await deleteTenant(tenantUser.id);
              await deleteUser(tenantUser.id);
            }
          }
          // If we can't add the tenant to the property's tenant's list then remove the user from the tenant's table and delete their account
          else {
            await deleteTenant(tenantUser.id);
            await deleteUser(tenantUser.id);
          }
        }
        // If we can't delete the token -> remove the tenant from the table and delete the user
        else {
          await deleteTenant(tenantUser.id);
          await deleteUser(tenantUser.id);
        }
      } else {
        // If we can't -> delete the user
        await deleteUser(tenantUser.id);
      }
    }
  };

  // Check if the token being used to access this page is valid
  useEffect(() => {
    checkToken();
  }, [registration_token]);

  useEffect(() => {
    if (validToken) {
      getLandlordAndPropertyDetails();
    }
  }, [validToken]);

  useEffect(() => {
    if (validToken) {
      setTenantInformation({
        ...tenantInformation,
        property_token: tokenInformation.property_token,
        move_in_date: tokenInformation.move_in_date,
        move_out_date: tokenInformation.move_out_date,
        rent: tokenInformation.rent,
      });
    }
  }, [tokenInformation]);

  return (
    <Layout
      body={
        <Container>
          {validToken ? (
            <div className="pt-5">
              <div className="text-center">
                <h1>Welcome to JASK</h1>
                <h4>Property Management</h4>
                <p className="mt-5">
                  You have been invited by{" "}
                  <span id="special-text">
                    {landlord.first_name + " " + landlord.last_name}
                  </span>{" "}
                  to <span id="special-text">{property.address}</span>
                </p>
                <p>Please complete the below form</p>
              </div>

              <Form.Group className="mt-2">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your first name"
                  value={tenantInformation.first_name}
                  onChange={(e) =>
                    setTenantInformation({
                      ...tenantInformation,
                      first_name: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your last name"
                  value={tenantInformation.last_name}
                  onChange={(e) =>
                    setTenantInformation({
                      ...tenantInformation,
                      last_name: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  value={tenantInformation.email}
                  onChange={(e) =>
                    setTenantInformation({
                      ...tenantInformation,
                      email: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter your phone number"
                  value={tenantInformation.phone_number}
                  onChange={(e) =>
                    setTenantInformation({
                      ...tenantInformation,
                      phone_number: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter a password you will use to login"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Form.Text className="muted">
                  Password must be 8-16 characters
                </Form.Text>
              </Form.Group>
              <p className="mt-5">
                The below details have been set by your landlord, please contact
                your landlord if you see any discrepancies
              </p>
              <Row className="justify-content-around">
                <Col md={3}>
                  <Form.Group className="mt-2 mb-2">
                    <Form.Label>Monthly Rent ($ CAD)</Form.Label>
                    <Form.Control value={tokenInformation.rent} disabled />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mt-2 mb-2">
                    <Form.Label>Move In Date</Form.Label>
                    <Form.Control
                      value={tokenInformation.move_in_date}
                      disabled
                    />{" "}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mt-2 mb-2">
                    <Form.Label>Move Out Date</Form.Label>
                    <Form.Control
                      value={tokenInformation.move_out_date}
                      disabled
                    />
                  </Form.Group>{" "}
                </Col>
              </Row>
              <Form.Check
                className="mt-3"
                type="checkbox"
                label="I certify that the above information is accurate to the best of my knowledge"
                checked={certify}
                onChange={(e) => setCertify(e.target.checked)}
              />
              <Button
                variant="outline-dark"
                className="mt-5 w-100"
                onClick={completeTenantRegistration}>
                Complete Registration
              </Button>
            </div>
          ) : (
            <h1 className="center">You seem to be lost friend.</h1>
          )}
        </Container>
      }
      protectedRoute={false}
    />
  );
};
export default TenantRegistrationPage;
